<template>
<div class="stallsingleInfo">
    <div class="left">
        <StallSingleOrderStatus />
    </div>
    <div class="right">
        <StallSingleOrderProduct />
        <StallSingleOrderPayments />
    </div>
</div>
</template>

<script>
export default {
name:"stallsingleInfo",
}
</script>

<style lang="scss" scoped>
.stallsingleInfo {
    display:flex;
    // justify-content:space-around;
}
.left{
    width:50%;
}
.right{
    width:50%;
}
@media (max-width: 786px) {
    .stallsingleInfo{
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        align-items:center;
    }
    .left{
    width:100%;
}
.right{
    width:100%;
    margin-top:30px;
}
}
</style>